<template>
  <div>
    <div class="d-flex">
      <!-- form input -->
      <b-form-group class="mb-0 mr-1">
        <b-form-input
          v-model="message"
          disabled
        />
      </b-form-group>

      <!-- button -->
      <b-button
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="relief-primary"
      >
        Copiar Chave do PicPay
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormInput, BFormGroup, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data () {
    return {
      message: '@contasinfinity'
    }
  },
  methods: {
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Chave do PicPay copiado com sucesso!',
          icon: 'BellIcon'
        }
      })
    },
    onError () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon'
        }
      })
    }
  }
}
</script>
