<template>
  <section>
    <div class="text-center">
      <h1 class="mt-5">
        Enviar comprovante do PicPay
      </h1>

      <p class="mb-2 pb-75">
        Adicione o valor do PicPay no seu saldo e envie o comprovante para a
        gente!
      </p>

      <p class="mb-2 pb-75">
        Aviso: Coloque abaixo o valor referente ao que foi transferido
      </p>
    </div>

    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="lg"
                label="Valor"
                label-for="number"
              >
                <cleave
                  id="number"
                  v-model="amount"
                  class="form-control"
                  :raw="true"
                  :options="options.number"
                  placeholder="Valor"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-media no-body>
                <b-media-aside>
                  <b-img
                    ref="previewEl"
                    rounded
                    :src="require('@/assets/images/avatars/default.jpeg')"
                    height="150"
                    width="150"
                  />
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    Upload do Comprovante
                  </b-button>
                  <b-form-file
                    ref="refInputEl"
                    v-model="receipt"
                    accept=".jpeg, .jpg, .png"
                    :hidden="true"
                    plain
                    @input="inputImageRenderer"
                  />

                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                  >
                    Limpar
                  </b-button>
                  <!--/ reset -->
                  <b-card-text>Permitido apenas JPG, ou PNG.</b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-button
          class="mt-2"
          variant="relief-primary"
          :disabled="loading"
          @click.stop="submit()"
        >
          <b-spinner
            v-if="loading"
            small
          />
          <span v-else> Enviar comprovante </span>
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BSpinner,
  BCardText,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import CopyPicPayCode from './CopyPicPayCode.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BImg,
    BButton,
    CopyPicPayCode,
    Cleave,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BSpinner
  },
  data: () => ({
    loading: false,
    userData: JSON.parse(localStorage.getItem('userData')),
    amount: '',
    receipt: null,
    options: {
      number: {
        prefix: 'R$ ',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 9,
        numeralDecimalScale: 2
      }
    }
  }),
  methods: {
    async submit () {
      this.loading = true

      if (this.amount === '' || this.amount === null) {
        this.$swal({
          title: 'Por favor, informe o valor transferido pelo PicPay',
          icon: 'error',
          confirmButtonText: 'Fechar',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        this.loading = false
        return
      }

      const url = '/user/balance/picpay/receipt'
      const params = new FormData()
      params.append('amount', this.amount)

      if (this.$refs.refInputEl.files.length > 0) {
        params.append('photo', this.$refs.refInputEl.files[0])
      }

      await this.$http
        .post(url, params)
        .then(res => {
          if (res.status === 422) {
            this.$swal({
              title:
                'Erro ao enviar comprovante!\n\nValor ou documento inválido.',
              icon: 'error',
              confirmButtonText: 'Fechar',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            return
          }
          this.$swal({
            title:
              'Comprovante enviado com sucesso!\n\nEm breve adicionaremos o seu saldo.',
            icon: 'success',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(() => {
            this.$router.push('/')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  setup () {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>
